<template>
    <div class="circle" v-bind:style="positionStyle">
        <div class="circle_box">
            <div v-for="(item,k) in list" :key="k">
                <div class="img_box" :style="{right:item.right,top:item.top}">
                    <div>
                        <div class="img_text">{{item.service}}</div>
                        <img class="img" :src="item.logoUrl">
                    </div>
                </div>
            </div>
            <div class="total_box">
                <div>{{totalLabel}}</div>
                <div style="font-weight: bold">{{totalNum}}</div>
            </div>
        </div>
        <div class="wave_box">
            <div class="svg_box" :style="svgBoxStyle">
                <div class="svg_item" v-for="(waveImg,key) in svgPath" v-bind:key="key">
                    <img :src="waveImg">
                </div>
            </div>
            <div class="mark_box"></div>
        </div>
    </div>
</template>
<script type="text/javascript">
import waveImg from '@/assets/center_v1_wave.png'
const boxWidth = 300
export default {
    data() {
        return {
            positionStyle: null,
            svgPath: [waveImg, waveImg, waveImg],
            svgBoxStyle: null,
            interval: null,
            totalNum:'',
            totalLabel:'',
        }
    },
    created() {
        this.conversion()
        this.svgStyleFun()
    },
    props: {
        list: {
            type: Array,
            default () {
                return []
            },
        },
        routerMsg: {
            type: Object,
        },
        app: {
            type: Object,
        },
        scale: {
            type: Number,
        }
    },
    watch: {
        list: {
            handler() {
                this.conversion()
                this.setSvgBoxStyle(0, true)
                this.svgStyleFun()
            },
            immediate: true,
        },
        app: {
            handler(val) {
                if(val){
                    this.getData()
                }
            },
            immediate: true,
        },
        routerMsg: {
            handler: function(val) {
                if (val) {
                    this.setPositionStyle()
                } else {
                    this.positionStyle = null
                }
            },
            immediate: true,
        },
        $route: {
            handler: function(route) {
                console.log(route)
                if (route.name == 'centerV1') {
                    this.svgStyleFun()
                } else {
                    window.clearInterval(this.interval)
                }
            },
            immediate: true
        }
    },
    methods: {
        //计算角度
        conversion() {
            let radian = 0.017453293
            let degree = 280 / this.list.length
            this.list.forEach((item, k) => {
                item.angle = degree * (k + 1) + 80
                item.right = Math.sin(item.angle * radian) * (boxWidth)/3*2 + 'px'
                item.top = Math.cos(item.angle * radian) * (boxWidth)/3*2 + 'px'
            })
        },
        setPositionStyle() {
            let scale = this.scale

            let itemH = 70 * scale

            let msg = this.routerMsg
            let { routerWidth } = this.routerMsg
            let obj = {
                left: msg.centerW + routerWidth.bottom + (40 + (scale - 1) * boxWidth) * scale + 'px',
                right: 'auto',
                bottom: (30 +itemH)*scale + 'px',
                transform: `scale(${scale})`
            }


            this.positionStyle = obj;
        },
        svgStyleFun() {
            this.$nextTick(() => {
                var translateX = 0
                var itemW = $('.svg_item').width()
                var stap = itemW / 20
                if (this.interval) {
                    window.clearInterval(this.interval)
                }
                this.interval = setInterval(() => {
                    translateX += stap
                    this.setSvgBoxStyle(translateX)
                    setTimeout(() => {
                        if (translateX >= itemW) {
                            translateX = 0
                            this.setSvgBoxStyle(translateX, true)
                        }
                    }, 850)
                }, 900)
            })
        },
        setSvgBoxStyle(x, b) {
            let obj = {
                transform: `translateX(-${x}px)`
            }
            if (b) {
                obj.transition = 'unset'
            }
            this.svgBoxStyle = obj
        },
        getData(){
            let url = '/home/queryAppSpecialData'
            let params = {
                appId:this.app.appId
            }
            this.$http.post(url,params).then(data=>{
                let {detail} = data
                this.totalNum = detail.data
                this.totalLabel = detail.title
            })
        },
    },
}

</script>
<style type="text/css" lang="scss" scoped="circle">
$itemWidth:300px;
.circle {
    position: fixed;
    right: 100px;
    bottom: 100px;

    .wave_box {
        position: absolute;
        height: $itemWidth;
        width: $itemWidth;
        border-radius: 50%;
        border: 3px solid transparent;
        overflow: hidden;
        top: 0;

        .svg_box {
            overflow: hidden;
            position: absolute;
            bottom: 45%;
            white-space: nowrap;
            transition: all .8s;
            text-align: right;

            .svg_item {
                height: 80px;
                display: inline-block;

                img {
                    height: 80px;
                }
            }
        }

        .mark_box {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100px;
            background: linear-gradient(to left, rgb(2, 6, 12) 40%, rgba(255, 255, 255, 0));
        }
    }

    .circle_box {
        position: relative;
        height: $itemWidth;
        width: $itemWidth;
        border-radius: 50%;
        border: 3px solid white;

        >div {
            position: absolute;
            left: 115px;
            top: 115px;
        }

        .img_box {
            position: relative;
            width: 70px;
            height: 70px;
            text-align: center;

            .img_text {
                height: 20px;
                line-height: 20px;
                color: white;
                position: relative;
                top: -6px;
                font-size: 16px;
            }

            .img {
                width: 50px;
                height: 50px;
            }
        }

        .total_box {
            position: absolute;
            width: 80%;
            top: 47%;
            left: -10%;
            bottom: 20px;
            color: white;
            font-size: 20px;
            text-align: left;
            background-color: #02060c;
            padding: 30px 0px;

            >div {
                &:nth-of-type(1) {
                    padding-left: 5px;
                    font-size: 24px;
                    transform: translateX(10%);
                }
                &:nth-of-type(2) {
                    margin-top: 10px;
                    font-size: 28px;
                    transform: translateX(30%);
                    padding-left: 5px;
                }
            }
        }
    }

}

</style>
