<template>
	<div class="route_canvas_box">
		<canvas ref="canvas" v-on:mousemove="mousemove" v-on:click="mouseClick"></canvas>
	</div>
</template>
<script type="text/javascript">
import insystemImg from '@/assets/center_v1_insystem.png'
import busImg from '@/assets/center_v1_bus.png'
const routerWidth = {
	step: 28,
	top: 60,
	bottom: 360,
}

const speedMsg = {
	baseSpeed: 4,
	speed: 5,
	baseLong: 100,
	minDistance: 100,
	pointerNum: 4,
}

const busMsg = {
	size:5,
	baseSize: routerWidth.top - routerWidth.step - 10,
}

function createdLine(a, b) {
	this.a = a;
	this.b = b;
}
createdLine.prototype.getX = function(y) {
	return (y - this.b) / this.a
}
createdLine.prototype.getY = function(x) {
	return this.a * x + this.b
}
export default {
	props: {
		scale: {
			type: Number,
		}
	},
	data() {
		return {
			canvas: null,
			context: null,
			interval: null,
			msg: null,
			insystemImg: null,
			busImg: null,
			lines: [],
			busList: [],
			speedList: [],
		}
	},
	created() {
		this.initMsg()
		this.$nextTick(() => {
			this.init();
			this.draw();
		})
	},
	watch: {
		$route: {
			handler: function(route) {
				if (route.name == 'center'||route.name == 'centerV1') {
					this.$nextTick(() => {
						if (!this.canvas) {
							this.init();
						}
						this.interval = window.setInterval(() => {
							this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
							this.draw();
						}, speedMsg.speed)
					})
				} else {
					// 防止内存泄漏
					window.clearInterval(this.interval)
				}
			},
			immediate: true
		}
	},
	// 防止内存泄漏
	beforeDestroy() {
		if (this.interval) {
			window.clearInterval(this.interval)
		}
	},
	// 防止内存泄漏
	deactivated() {
		if (this.interval) {
			window.clearInterval(this.interval)
		}
	},
	methods: {
		draw() {
			this.drawRouter()
			this.setSpeed()
			this.drawInSystem()
			// this.drawBus()
		},
		init() {
			this.canvas = this.$refs.canvas
			this.context = this.canvas.getContext('2d')
			this.setStyle()
		},
		initMsg() {
			let width = $(window).width()
			let height = $(window).height() * 0.7

			this.msg = {
				width,
				height,
				centerW: width / 2,
				routerWidth,
			}
			this.$emit('routerMsg', this.msg)
		},
		imgLoaded(src, callBack) {
			let img = new Image()
			img.onload = () => {
				callBack && callBack()
			}
			img.src = src;
			return img
		},
		drawInSystem() {
			var fun = () => {
				let msg = this.msg;
				let imgW = this.insystemImg.width * this.scale
				let imgH = this.insystemImg.height * this.scale
				let ch = this.canvas.height

				let rw = (routerWidth.bottom + routerWidth.top) / 2 * this.scale
				let x = msg.centerW - rw / 2

				let rh = rw * imgH / imgW
				let y = ch - rh - 60 * (this.scale > 1 ? this.scale : 1)

				let el = this.insystemImg
				this.context.drawImage(el, x, y, rw, rh)
			}
			if (!this.insystemImg) {
				this.insystemImg = this.imgLoaded(insystemImg, fun)
			} else {
				fun()
			}
		},
		createdBus() {
			let step = 0
			// let step = Math.stochastic(-routerWidth.step, routerWidth.step)
			let msg = this.msg

			let lineObj = {
				x1: msg.centerW + step,
				x2: msg.centerW + step * (routerWidth.bottom / routerWidth.top),
				y1: 0,
				y2: msg.height
			}

			let { y2, x2, y1, x1 } = lineObj

			let a = (y2 - y1) / (x2 - x1)
			let b = y2 - a * x2

			let obj = {
				y: msg.height,
				fx: this.fx({ a, b }),
				nextDistance: Math.stochastic(msg.height - 300, msg.height + 100)
			}

			if (x1 == x2) {
				obj.fx.getX = () => {
					return lineObj.x1
				}
			}

			return obj;
		},
		drawBus() {
			var fun = (bus) => {
				let msg = this.msg;
				let imgW = this.busImg.width * this.scale
				let imgH = this.busImg.height * this.scale
				// let ch = this.canvas.height

				let size = busMsg.size
				let baseSize = busMsg.baseSize

				let rw = ((routerWidth.bottom + routerWidth.top) / size * (bus.y / msg.centerW) + baseSize) * this.scale
				let x = bus.fx.getX(bus.y) - rw / 2

				let rh = rw * imgH / imgW
				let y = bus.y

				let el = this.busImg
				this.context.drawImage(el, x, y, rw, rh)
			}
			if (!this.busImg) {
				this.busImg = this.imgLoaded(busImg, this.drawBus)
			} else {
				const busNum = 1
				let busList = this.busList
				if (busList && busList.length < busNum) {
					if (busList.length == 0) {
						busList.unshift(this.createdBus())
					} else if (busList[0].y >= busList[0].nextDistance) {
						busList.unshift(this.createdBus())
					}
				}
				if (busList[busList.length - 1].y <= -100) {
					busList.pop()
				}
				if (busList && busList.length) {
					busList.forEach(one => {
						one.y -= .2
						fun(one)
					})
				} else {
					let bus = this.createdBus()
					this.busList = [bus]
					fun(bus)
				}
			}
		},
		setStyle() {
			let width = $(window).width()
			let height = $(window).height() * 0.7
			this.canvas.width = width;
			this.canvas.height = height;
		},
		getGrd({ x1, y1, x2, y2, color }) {
			var grd = this.context.createLinearGradient(x1, y1, x2, y2);
			color = color.forEach(one => {
				grd.addColorStop(one.s, one.v);
			})
			color = grd
			return ({ x1, y1, x2, y2, color })
		},
		setRouter() {
			this.lines = []

			let y1 = 0,
				y2 = this.msg.height
			let line1 = {
				x1: this.msg.centerW - routerWidth.top,
				x2: this.msg.centerW - routerWidth.bottom,
				color: [{ s: 0, v: '#112f5d' }, { s: .15, v: 'rgb(125,125,125)' }, { s: 1, v: 'rgb(125,125,125)' }]
			}
			let line2 = {
				x1: this.msg.centerW + routerWidth.top,
				x2: this.msg.centerW + routerWidth.bottom,
				color: [{ s: 0, v: '#112f5d' }, { s: .15, v: 'rgb(125,125,125)' }, { s: 1, v: 'rgb(125,125,125)' }]
			}
			let line3 = {
				x1: this.msg.centerW - routerWidth.top + routerWidth.step,
				x2: this.msg.centerW - routerWidth.bottom + (routerWidth.bottom * routerWidth.step / (routerWidth.top)),
				color: [{ s: 0, v: '#112f5d' }, { s: .15, v: '#386c9c' }, { s: 1, v: '#386c9c' }]
			}
			let line4 = {
				x1: this.msg.centerW + routerWidth.top - routerWidth.step,
				x2: this.msg.centerW + routerWidth.bottom - (routerWidth.bottom * routerWidth.step / (routerWidth.top)),
				color: [{ s: 0, v: '#112f5d' }, { s: .15, v: '#386c9c' }, { s: 1, v: '#386c9c' }]
			}

			this.lines.push(this.getGrd({ ...line1, y1, y2 }))
			this.lines.push(this.getGrd({ ...line2, y1, y2 }))
			this.lines.push(this.getGrd({ ...line3, y1, y2 }))
			this.lines.push(this.getGrd({ ...line4, y1, y2 }))
			this.setRouterLine()
		},
		setRouterLine() {
			this.lines.forEach(one => {
				if (!one.fx) {
					one.fx = this.fx({ lineOption: one })
				}
			})
			console.log(this.lines)
		},
		drawRouter() {
			if (!this.lines || this.lines.length != 4) {
				this.setRouter()
			}
			this.lines.forEach(one => {
				this.line(one)
			})
		},
		createdSpeedPointer() {
			let step = Math.stochastic(-routerWidth.top, routerWidth.top)

			let lineObj = {
				x1: this.msg.centerW + step,
				x2: this.msg.centerW + step * (routerWidth.bottom / routerWidth.top),
				y1: 0,
				y2: this.msg.height
			}

			let { y2, x2, y1, x1 } = lineObj

			let a = (y2 - y1) / (x2 - x1)
			let b = y2 - a * x2

			let obj = {
				y: 0,
				fx: this.fx({ a, b }),
				color: `rgba(255,255,255,${Math.stochastic(0,.8)})`,
				nextDistance: Math.stochastic(speedMsg.minDistance, speedMsg.minDistance + 100)
			}
			return obj;
		},
		setSpeed() {
			const speedLineNum = speedMsg.pointerNum
			let list = this.speedList

			if (list && list.length < speedLineNum) {
				if (list.length == 0) {
					list.unshift(this.createdSpeedPointer())
				} else if (list[0].y >= list[0].nextDistance) {
					list.unshift(this.createdSpeedPointer())
				}
			} else if (list[list.length - 1].y >= this.canvas.height) {
				list.pop()
			}
			list.forEach(one => {
				one.y += speedMsg.baseSpeed
				let obj = {
					y1: one.y,
					x1: one.fx.getX(one.y),
					y2: one.y + this.getSpeed(one.y),
					x2: one.fx.getX(one.y + this.getSpeed(one.y)),
					color: one.color
				}
				this.drawSpeed(obj)
			})
		},
		getSpeed(y) {
			return y / this.canvas.height * speedMsg.baseLong
		},
		drawSpeed(one) {
			let obj = {
				color: '#fff',
				...one,
			}
			this.line(obj, 1)
		},
		line({ x1, y1, x2, y2, color }, d = 2) {
			this.context.save()
			this.context.beginPath()
			this.context.moveTo(x1, y1)
			this.context.lineTo(x2, y2)
			this.context.lineTo(x2 + d, y2)
			this.context.lineTo(x1 + d, y1)
			if (color) {
				this.context.fillStyle = color
			}
			this.context.fill()
			this.context.restore()
		},
		fx({ lineOption, a, b }) {
			if (lineOption) {
				let line
				if (typeof lineOption == 'object') {
					line = lineOption
				} else if (typeof lineOption == 'number') {
					line = this.lines[lineOption]
				}
				let { y2, x2, y1, x1 } = line
				if (a == undefined) {
					a = (y2 - y1) / (x2 - x1)
				}
				if (b) {
					let nb = y2 - a * x2
					b = nb + b
				} else {
					b = y2 - a * x2
				}
			}
			return new createdLine(a, b)
		},
		fxb(x, y, k) {
			let line = this.lines[k]

			let { y2, x2, y1, x1 } = line

			let a = (y2 - y1) / (x2 - x1)
			let b = y2 - a * x2
			return (a * x + b <= y)
		},
		inRoute(e) {
			return this.fxb(e.layerX, e.layerY, 2) && this.fxb(e.layerX, e.layerY, 3)
		},
		mousemove(e) {
			if (this.inRoute(e)) {
				e.target.style.cursor = 'pointer'
			} else {
				e.target.style.cursor = ''
			}
		},
		mouseClick(e) {
			if (this.inRoute(e)) {
				this.$emit('initSystem')
			}
		}
	}
}

</script>
<style type="text/css" lang="scss" scoped="route_canvas_box">
.route_canvas_box {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	left: 0;
	z-index: -9;

	canvas {
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: 0;
	}
}

</style>
