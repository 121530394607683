<template>
	<div class="center_v1_map" :style="boxStyle">
		<canvas ref="canvas" :style="canvasStyle" class="img_canvas"></canvas>
		<div class="logo_box">
			<img :src="logoImg">
		</div>
	</div>
</template>
<script type="text/javascript">
import mapImg from '@/assets/center_v1_map.png'
import arrowImg from '@/assets/center_v1_arrow.png'
import stationImg from '@/assets/center_v1_station.png'
import logoImg from '@/assets/logo_home.png'
export default {
	props: {
		routerMsg: {
			type: Object
		},
		app: {
			type: Object
		},
        scale: {
            type: Number,
        }
	},
	data() {
		return {
			logoImg,
			bgImg: null,
			arrowImg: null,
			stationImg: null,
			boxStyle: null,
			canvasStyle: null,
			canvas: null,
			context: null,
			routerList: null,
			stationList: null,
			interval: null,

			pointerNo: 0,
		}
	},
	watch: {
		routerMsg: {
			handler: function(val) {
				if (val) {
					this.setBoxStyle()
				} else {
					this.boxStyle = null
				}
			},
			immediate: true,
		},
	},
	// 防止内存泄漏
	beforeDestroy() {
		if (this.interval) {
			window.clearInterval(this.interval)
		}
	},
	// 防止内存泄漏
	deactivated() {
		if (this.interval) {
			window.clearInterval(this.interval)
		}
	},
	created() {
		this.$nextTick(() => {
			this.init()
			this.bgImg = this.imgLoaded(mapImg, () => {
				this.drawBg()
				setTimeout(()=>{
					this.getRouterData(() => {
						this.arrowMoveFun()
					})
				})
			});
		})
	},
	methods: {
		init() {
			this.canvas = this.$refs.canvas
			this.canvas.width = $(this.canvas).width()
			this.canvas.height = $(this.canvas).height()
			this.context = this.canvas.getContext('2d')
		},
		imgLoaded(src, callBack) {
			let img = new Image()
			img.onload = () => {
				callBack && callBack()
			}
			img.src = src;
			return img
		},
		drawBg() {
			let el = this.bgImg;

			let cw = this.canvas.width
			let ch = this.canvas.height

			this.context.drawImage(el, 0, 0, cw, ch - 60)
		},
		arrowMoveFun() {
			if (this.arrowImg) {
				window.clearInterval(this.interval)
				this.interval = window.setInterval(() => {
					this.pointerNo++
					if (this.pointerNo >= this.routerList.length) {
						this.pointerNo = 0
					}
					this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
					this.drawBg()
					this.drawArrow()
					this.drawStationFun()
				}, 30)
			} else {
				this.arrowLoaded(this.arrowMoveFun)
			}
		},
		drawStationFun() {
			if (this.stationImg) {
				let el = this.stationImg
				let iw = el.width
				let ih = el.height

				this.stationList && this.stationList.forEach((pointer, k) => {
					let scale
					if (k == 0) {
						scale = this.scale * 1.8
					} else {
						scale = this.scale * .6
					}
					let w = 40 * scale
					let h = w * (ih / iw)
					let x = pointer.x - w * (1 / 2)
					let y = pointer.y - h * (5 / 9)
					this.context.drawImage(el, x, y, w, h)
				})
			} else {
				this.stationImg = this.imgLoaded(stationImg, this.drawStationFun)
			}
		},
		getRouterData(callBack) {
			let imgData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
			let data = imgData.data

			let xl = this.canvas.width
			let yl = this.canvas.height

			let list = []
			let redList = []

			for (let y = 0; y < yl; y++) {
				let l = []
				let rl = []
				for (let x = 0; x < xl; x++) {
					let ind = (y * xl + x) * 4;
					let r = data[ind];
					let g = data[ind + 1];
					let b = data[ind + 2];
					// let a = data[ind + 3];
					//取蓝色值
					if ((b - g > 40) && (b - r > 80) && r !== 0 && g !== 0) {
						l.push(x)
					}
					//取红色值
					if (r > 200 && r - g > 200 && r- b > 200) {
						console.log(`rgb(${r},${g},${b})`)
						if (!rl.length && rl[0] - x > 10) {
							rl.push(x)
						} else {
							rl.push(x)
						}
					}
				}
				//取中间值
				if (l.length >= 2) {
					let x = (l[0] + l[l.length - 1]) / 2
					list.push({ x, y })
				} else if (l.length == 1) {
					list.push({ x: l[0], y })
				}

				if (rl.length) {
					if (!redList.some(one => { return (one.y - y) > 10 })) {
						redList.push({ x: rl[0], y })
					}
				}
			}
			this.routerList = list.reverse()
			this.stationList = redList.reverse()
			callBack && callBack()
		},
		arrowLoaded(callBack) {
			this.arrowImg = this.imgLoaded(arrowImg, () => {
				if (callBack) {
					callBack()
				} else {
					this.drawArrow()
				}
			});
		},
		drawArrow() {
			if (this.arrowImg) {
				let minw = 12
				let maxw = 50
				let routerList = this.routerList
				let pointer = routerList[this.pointerNo]

				let w = (maxw - ((maxw - minw) * (this.pointerNo / routerList.length))) * this.scale

				if (pointer) {
					let x = pointer.x - w / 2
					let y = pointer.y - w / 2
					this.context.drawImage(this.arrowImg, x, y, w, w)
				}
			}
		},
		setBoxStyle() {
			let msg = this.routerMsg
			let right = (msg.centerW + msg.routerWidth.bottom - (40 + (this.scale - 1) * 350) * this.scale) + 'px'
			if (this.scale <= 1) {
				right = (msg.centerW + msg.routerWidth.bottom - 50) + 'px'
			}
			let obj = {
				right
			};
			this.boxStyle = obj;
		},
	}
}

</script>
<style type="text/css" scoped="center_v1_map" lang="scss">
.center_v1_map {
	position: absolute;
	bottom: 0;
	left: 0;
	top: 50%;
	z-index: -10;

	.img_canvas {
		position: absolute;
		display: inline-block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.logo_box {
		width: 80%;
		position: absolute;
		bottom: 30px;
		left: 10%;

		img {
			width: 85%;
		}
	}
}

</style>
