<template>
	<div class="center_v1_bg">
		<div class="bg_img_box">
			<img :src="bgImg">
		</div>
	</div>
</template>
<script type="text/javascript">
import bgImg from '@/assets/top-v1.png'
export default{
	data(){
		return{
			bgImg
		}
	},
}
</script>
<style type="text/css" lang="scss" scoped="center_v1_bg">
.center_v1_bg {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	left: 0;
	background: #02060c;
	z-index: -10;
	.bg_img_box{
		display: inline-block;
		position:absolute;
		width:100%;
		height: 55%;
		top:0;
		z-index: -9;
		img{
			width:100%;
			height: 100%;
		}
	}
}

</style>
