<template>
	<div class="home">
		<bg></bg>
		<div class="top_out" v-on:click="logout">
			<img src="../assets/operate/nav_icon_logout.png" />
		</div>
		<apps :scale="scale" :appItems="appItems" @setAlive="setAlive"></apps>
		<canvas-router :scale="scale" @routerMsg="routerMsgFun" @initSystem="initSystem"></canvas-router>
		<circle-system :scale="scale" :list="circleList" :routerMsg="routerMsg" :app="aliveApp"></circle-system>
		<centerMap :scale="scale" :routerMsg="routerMsg" :app="aliveApp"></centerMap>
	</div>
</template>
<script type="text/javascript">
import apps from '@/components/center/v1/apps'
import bg from '@/components/center/v1/bg'
import canvasRouter from '@/components/center/v1/canvasRouter'
import circleSystem from '@/components/center/v1/circle'
import map from '@/components/center/v1/map'
export default {
	name: 'homev1',
	data() {
		const bw = 1920
		let tw = $(window).width()
		let scale = tw / bw
		return {
			appItems: [],
			routerMsg: null,
			aliveApp: null,
			scale,
		}
	},
	components: {
		apps,
		bg,
		centerMap: map,
		canvasRouter,
		circleSystem,
	},
	computed: {
		circleList: function() {
			let aliveApp = this.aliveApp
			// console.log('aliveApp',aliveApp)
			if (aliveApp && aliveApp.appServices) {
				return aliveApp.appServices
			} else {
				return []
			}
		}
	},
	created() {
		this.getAppItems()
	},
	methods: {
		handleScroll(e) {
			if (this.lock) {
				return false
			}
			this.$refs.systems.slideNext(e.deltaY)
		},
		logout() {
			this.$confirm('您确定退出该系统？', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				let url = '/logout'
				this.$http.post(url).then(() => {
					this.$util.removeStor('token')
					this.$store.state.info = null
					window.location.reload()
					this.$router.push({ name: 'login' })
				})
			})
		},
		getAppItems() {
			let url = '/app/info/queryUserApp'
			this.$http.post(url).then(({ detail }) => {
				console.log(detail)
				this.appItems = detail
			})
		},
		routerMsgFun(msg) {
			this.routerMsg = msg
		},
		setAlive(app) {
			console.log('aliveApp', app)

			this.aliveApp = app
		},
		initSystem() {
			this.getAppToken()
		},
		bankPage(url) {
			var id = 'bank_a'
			var a = null
			// 防止反复添加
			if (!document.getElementById(id)) {
				a = document.createElement('a')
				a.setAttribute('href', url)
				a.setAttribute('target', '_blank')
				a.setAttribute('id', id)
				document.body.appendChild(a)
			} else {
				a = document.getElementById(id)
				a.setAttribute('href', url)
			}
			a.style.display = 'none'
			a.click()
		},
		getAppToken() {
			let app = this.aliveApp
			console.log(app)

			if (/^(\/|#)/.test(app.appAddr)) {
				this.$router.push({ path: app.appAddr })
				return
			}

			if (!app.addr) {
				this.bankPage(app.appAddr)
				return false
			}

			var acount = this.$store.state.info
			let url = '/app/info/getAppToken'
			let params = {
				userName: acount.userName,
				password: acount.password,
				appId: app.appId,
			}
			this.$http.post(url, params).then(({ detail }) => {
				this.$set(app, 'token', detail.token)
				let url = app.appAddr
				console.log(app.appAddr)
				if (/\?/.test(url)) {
					url += '&token=' + app.token
				} else {
					url += '?token=' + app.token
				}
				console.log(url)
				this.bankPage(url)
			})
		},
	},
}

</script>
<style lang="scss" scoped="home_v1">
.home {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #02060c;

	.top_out {
		position: absolute;
		right: 10px;
		top: 10px;
		cursor: pointer;
		z-index: 4000;
	}
}

</style>
