<template>
	<div class="apps_box" v-if="list">
		<transition-group name="bounce" tag="ul" v-if="list&&list.length" :style="ulStyle">
			<li v-for="(one,key) in list" v-bind:key="one.appId" v-bind:class="{'alive':one.alive}" v-bind:style="liStyle(one,key)">
				<div class="app_item" v-on:click="itemAliveFun(one)">
					<img :src="aliveImg" v-if="one.alive">
					<img :src="one.logoImg" v-else>
					<p>{{one.cName}}</p>
				</div>
			</li>
		</transition-group>
	</div>
</template>
<script type="text/javascript">
import aliveImg from '@/assets/center_v1_selected.png'
export default {
	props: {
		appItems: {
			type: Array,
		},
		scale: {
			type: Number,
		}
	},
	data() {
		return {
			aliveImg,
			list: []
		}
	},
	watch: {
		appItems: {
			handler: function(val) {
				if (val) {
					var list = val.slice(0)
					let length = list.length
					let aliveApp = null
					list.forEach((one, key) => {
						if (key == Math.floor(length / 2)) {
							one.alive = true
							this.$emit('setAlive', one)
						} else {
							one.alive = false
						}
						if (one.cName == '实时监控') {
							aliveApp = one
						}
					})
					this.list = list
					if (aliveApp) {
						this.itemAliveFun(aliveApp)
					}
				} else {
					this.list = []
				}
			},
			immediate: true
		},
	},
	computed: {
		ulStyle: function() {
			const baseSideWidth = 70
			let width = Math.floor($(window).width() / 19) + baseSideWidth
			let obj = {
				transform:`scale(${this.scale})`
			}
			if (this.list && this.list.length % 2 == 0) {
				obj.transform += ` translateX(-${width/2}px)`
			}
			return obj
		},
	},
	methods: {
		itemAliveFun(item) {
			let list = this.list
			let appAlive = list.filter(one => one && one.alive)[0]
			appAlive.alive = false
			item.alive = true

			this.$emit('setAlive', item)

			for (let i = 0; i < list.length; i++) {
				if (list[i].appId == appAlive.appId) {
					list.splice(i, 1, item)
				} else if (list[i].appId == item.appId) {
					list.splice(i, 1, appAlive)
				}
			}
		},
		liStyle(one, key) {
			const baseSideWidth = 70
			let width = Math.floor($(window).width() / 19) + baseSideWidth
			let obj = {
				width: width + 'px',
				fontSize: '21px'
			}
			let left
			let length = this.list.length
			if (key < Math.floor(length / 2)) {
				left = (width + 20) * (key - Math.floor(length / 2)) - 15
			}
			if (key > Math.floor(length / 2)) {
				left = (width + 20) * (key - Math.floor(length / 2)) + 15
			}
			if (left) {
				obj = {
					...obj,
				}
			} else {
				obj.width = width * 1.2 + 30 + 'px'
				obj.fontSize = '36px'
				obj.fontWeight = 'bold'
			}
			if (one.alive) {
				return {
					...obj
				}
			} else {
				return {
					...obj
				}
			}
		},
	},
}

</script>
<style type="text/css" lang="scss" scoped="apps_box">
.bounce-move {
	transition: all 1s;
}

.apps_box {
	position: absolute;
	top: 20%;
	transform: translateY(-50%);
	width: 100%;
	text-align: center;

	ul {
		li {
			line-height: 40px;
			display: inline-block;
			cursor: pointer;
			text-align: center;
			color: #fff;
			transition: all .6;
			position: relative;
			top: 25%;
			opacity: .6;
			letter-spacing: 2px;

			.app_item {
				img {
					width: 50%;
				}
				p{
					height: 40px;
				}
			}

			&.alive {
				opacity: 1;

				img {
					width: 60%;
				}
			}
		}
	}
}

</style>
